<style lang="scss">
.kontakte {
  .kontakt-box {
    margin-top: 30px;
    a {
      &:hover {
        color: $blue !important;
      }
    }
  }
  .top-header {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 500px;
    margin-bottom: 50px;
  }
}
</style>

<template>
  <div class="kontakte content">
    <div class="container">
      <h1>Ihr Kontakt für erfolgreiche Kommunikation</h1>
      <div class="row">
        <div class="col-md-12 col-lg-6 kontakt-box">
          <h2>Werbemarkt Steiermark</h2>
          <p>
            Gadollaplatz 1<br />
            8010 Graz
          </p>
          <p>
            Tel.: <a href="tel:0316/875 33 02">0316/875 33 02</a><br />
            E-Mail: <a href="mailto:meinewerbung@kleinezeitung.at">meinewerbung@kleinezeitung.at</a>
          </p>
        </div>
        <div class="col-md-12 col-lg-6 kontakt-box">
          <h2>Werbemarkt Kärnten</h2>
          <p>
            Hasnerstraße 2<br />
            9020 Klagenfurt
          </p>
          <p>
            Tel.: <a href="tel:0463/58 00 286">0463/58 00 286</a><br />
            E-Mail: <a href="mailto:meinewerbung@kleinezeitung.at">meinewerbung@kleinezeitung.at</a>
          </p>
        </div>
        <div class="col-md-12 col-lg-6 kontakt-box">
          <h2>Werbemarkt National</h2>
          <p>
            Lobkowitzplatz 1<br />
            1010 Wien
          </p>
          <p>
            Tel.: <a href="tel:01/512 16 18">01/512 16 18</a><br />
            E-Mail: <a href="mailto:meinewerbung@kleinezeitung.at">meinewerbung@kleinezeitung.at</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Kontakte',
});
</script>
